import React from 'react'

import packageJson from '../../../package.json'

export default function Footer() {
    return (
        <div style={{marginTop: 20, marginBottom: 20}}>
            <a className="footer-link" href="http://www.nic.it">
                <img
                    height={25}
                    role="presentation"
                    src="/logo-registro.svg"
                    className="itlogo-footer"
                    alt="registro.it"
                />
            </a>
            &nbsp;- v{packageJson.version}
        </div>
    )
}
