import React, {useContext} from 'react'
import {Card} from 'antd'
import {Notices, RdapConformance} from '@nic/rdap-ui'
import {IHelp} from '@nic/rdap-api/types/rdap'
import {helpFetch, helpInitialState, helpReducer} from '@nic/rdap-api'
import {AuthContext} from '@nic/kc-auth'

function Content(props: IHelp) {
    return (
        <Card title={'Detail'}>
            {/*{JSON.stringify(props)}*/}
            {props.rdapConformance && (
                <Card title={'RdapConformance'}> {RdapConformance(props.rdapConformance)}</Card>
            )}
            {props.notices && Notices(props.notices)}
        </Card>
    )
}

export function Help() {
    const [state, dispatch] = React.useReducer(helpReducer, helpInitialState)
    const {keycloak} = useContext(AuthContext)

    React.useEffect(() => {
        // inizializza rdapAxios con il token dentro
        helpFetch(dispatch, keycloak?.authenticated)
    }, [keycloak?.authenticated])

    return (
        <div>
            <h1>Help</h1>
            <div style={{paddingLeft: '10%', paddingRight: '10%'}}>
                {state.loading && <div>Loading....</div>}
                {state.data && console.log(JSON.stringify(state.data, null, 3))}
                {state.data && <Content {...state.data} />}
            </div>
        </div>
    )
}
