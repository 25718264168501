import React from 'react'
import './App.css'
import {AuthProvider, Login} from '@nic/kc-auth'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {Lookup} from './Scenes/Lookup/Lookup'
import 'antd/dist/antd.css'
import Footer from './Components/Footer/Footer'
import {HomePageRDAPSearchForm} from './Scenes/HomePage/HomePage'
import Search from './Scenes/Search/Search'
import {ReverseSearch} from './Scenes/ReverseSearch/ReverseSearch'
import {Menu} from './Components/Menu/Menu'
import {User} from './Components/User/User'
import {api} from '@nic/rdap-api'
import Keycloak from 'keycloak-js'
import {Help} from './Scenes/Help/Help'

const RdapLogin: React.FC = () => {
    return <Login redirectTo={'/'}/>
}

function App(): JSX.Element {
    const handleToken = (kc: Keycloak.KeycloakInstance) => {
        api(kc)
    }

    return (
        <AuthProvider
            keycloakJson={'/keycloak.json'} onKeycloakInstance={handleToken} loginOnDemand>
            <BrowserRouter>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Menu/>
                    <User/>
                </div>
                <div className="App">
                    <div>
                        <Switch>
                            <Route path={'/'} exact component={HomePageRDAPSearchForm}/>
                            <Route path={'/login'} exact component={RdapLogin}/>
                            <Route path={'/search'} exact component={Search}/>
                            <Route path={'/domains'} exact component={Search}/>
                            <Route path={'/:pathSegment/:value'} exact component={Lookup}/>
                            <Route path={'/reverse_search'} exact component={ReverseSearch}/>
                            <Route path={'/domains/reverse_search/:role'} component={ReverseSearch}/>
                            <Route path={'/help'} component={Help}/>
                            <Route path="*" component={() => <div>No page found!</div>}/>
                            {/*/!*<KeycloakProtectedRoute allowed path={'/'} exact component={HomePageRDAPSearchForm} />*!/*/}
                            {/*<KeycloakProtectedRoute*/}
                            {/*  path={'/lookup/:pathSegment/:value'}*/}
                            {/*  exact*/}
                            {/*  component={Lookup}*/}
                            {/*  allowed*/}
                            {/*/>*/}
                            {/*<KeycloakProtectedRoute*/}
                            {/*  path={'/:pathSegment/:value'}*/}
                            {/*  exact*/}
                            {/*  component={Lookup}*/}
                            {/*  allowed*/}
                            {/*/>*/}
                            {/*<KeycloakProtectedRoute path={'/search'} exact component={Search} allowed />*/}
                            {/*<KeycloakProtectedRoute path={'/domains'} exact component={Search} allowed />*/}
                            {/*<KeycloakProtectedRoute*/}
                            {/*  path={'/reverse_search'}*/}
                            {/*  exact*/}
                            {/*  component={ReverseSearch}*/}
                            {/*  allowed*/}
                            {/*/>*/}
                            {/*<KeycloakProtectedRoute*/}
                            {/*  path={'/domains/reverse_search/:role'}*/}
                            {/*  component={ReverseSearch}*/}
                            {/*  allowed*/}
                            {/*/>*/}
                            {/*/!* Sezione protetta - d'esempio - da rimuovere*!/*/}
                            {/*<KeycloakProtectedRoute*/}
                            {/*  path="/protected"*/}
                            {/*  exact*/}
                            {/*  component={HomePageRDAPSearchForm}*/}
                            {/*  allowed*/}
                            {/*/>*/}
                            {/*<KeycloakProtectedRoute path="/help" exact component={Help} allowed />*/}
                        </Switch>
                        <Menu/>
                        <Footer/>
                    </div>
                </div>
            </BrowserRouter>
        </AuthProvider>
    )
}

export default App
