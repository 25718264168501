import React from 'react'
import {AllElements, Contacts, Summary} from '@nic/rdap-ui'
import {IEntity, INameServer} from '@nic/rdap-api/types/rdap'
import {IViewerContentType} from '@nic/ui-antd/build/CardWithViewer/Type/IViewerContentType'

/***
 * Contenuto per Oggetto Nameserver
 * @param props
 */
export const buildContentNameserver = (props: INameServer) => {
  const contents: IViewerContentType[] = []
  const registrant: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('registrant')
  )
  const registrar: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('registrar')
  )
  const administrative: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('administrative')
  )
  const technical: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('technical')
  )

  contents.push({
    component: <Summary {...props} />,
    label: <>Summary</>,
  })

  if (registrant && registrant.length > 0) {
    contents.push({
      component: <Contacts contacts={registrant}/>,
      label: <>Registrant</>,
    })
  }

  if (registrar && registrar.length > 0) {
    contents.push({
      component: <Contacts contacts={registrar}/>,
      label: <>Registrar</>,
    })
  }

  if (administrative && administrative.length > 0) {
    contents.push({
      component: <Contacts contacts={administrative}/>,
      label: <>Administrative</>,
    })
  }

  if (technical && technical.length > 0) {
    contents.push({
      component: <Contacts contacts={technical}/>,
      label: <>Technical</>,
    })
  }
  contents.push({
    component: (
        <div style={{padding: 20}}>
          <AllElements {...props} />
        </div>
    ),
    label: <>Elements</>,
  })

  return contents
}
