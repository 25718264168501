import {Dropdown, Menu} from 'antd'
import {LogoutOutlined, UserOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import {AuthConsumer} from '@nic/kc-auth'
import React from 'react'

export function User() {
  return (
      <AuthConsumer>
        {({keycloak}) => {
          const tp: any = keycloak?.tokenParsed
          const menu = (
              <Menu onClick={() => keycloak && keycloak.logout()}>
                <Menu.Item key="1" icon={<LogoutOutlined/>}>
                  Logout
                </Menu.Item>
              </Menu>
          )
          console.log(' kc', keycloak)
          return (
              <div style={{textAlign: 'right'}}>
                {keycloak && keycloak.authenticated && (
                    <Link to={'/'}>
                      <Dropdown.Button overlay={menu} placement="bottomCenter" icon={<UserOutlined/>}>
                        {tp && tp.preferred_username}
                      </Dropdown.Button>
                    </Link>
                )}
              </div>
          )
        }}
      </AuthConsumer>
  )
}
