import {ClusterOutlined, IdcardOutlined, SafetyOutlined} from '@ant-design/icons'
import React from 'react'
import {IDomain, IEntity} from '@nic/rdap-api/types/rdap'
import {AllElements, Contacts, DomainInfo, Nameservers, Raw, SecureDNS, Summary} from '@nic/rdap-ui'
import {IViewerContentType} from '@nic/ui-antd'

/**
 *  Composizione delle Cards RDAP usate per visualizzare il contenuto dell'Oggetto Dominio
 * @param props
 */
export const buildContentDomain = (props: IDomain) => {
  const contents: IViewerContentType[] = []

  const registrant: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('registrant')
  )
  const registrar: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('registrar')
  )
  const administrative: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('administrative')
  )
  const technical: IEntity[] | undefined = props.entities?.filter(
      (entity) => entity.roles && entity.roles.includes('technical')
  )

  contents.push({label: 'Summary', component: Summary(props)})
  contents.push({label: 'Domain', component: DomainInfo(props)})

  if (registrant && registrant.length > 0) {
    contents.push({
      component: Contacts({contacts: registrant, rdapConformance: props.rdapConformance}),
      label: (
          <>
            <IdcardOutlined/> Registrant{' '}
          </>
      ),
    })
  }

  if (registrar && registrar.length > 0) {
    contents.push({
      component: <Contacts contacts={registrar} rdapConformance={props.rdapConformance}/>,
      label: (
          <>
            <IdcardOutlined/> Registrar
          </>
      ),
    })
  }

  if (administrative && administrative.length > 0) {
    contents.push({
      component: <Contacts contacts={administrative} rdapConformance={props.rdapConformance}/>,
      label: (
          <>
            <IdcardOutlined/> Administrative
          </>
      ),
    })
  }

  if (technical && technical.length > 0) {
    contents.push({
      component: Contacts({contacts: technical, rdapConformance: props.rdapConformance}),
      label: (
          <>
            <IdcardOutlined/> Technical{' '}
          </>
      ),
    })
  }

  if (props.nameservers && props.nameservers.length > 0) {
    contents.push({
      component: Nameservers(props.nameservers),
      label: (
          <>
            <ClusterOutlined/> Nameservers{' '}
          </>
      ),
    })
  }

  if (props.secureDNS && props.secureDNS.delegationSigned) {
    contents.push({
      component: SecureDNS(props.secureDNS),
      label: (
          <>
            <SafetyOutlined/> SecureDNS{' '}
          </>
      ),
    })
  }

  contents.push({
    component: (
        <div style={{padding: 20}}>
          <AllElements {...props} />
        </div>
    ),
    label: 'All Elements',
  })

  contents.push({
    component: Raw(props),
    label: 'Raw',
  })

  return contents
}
