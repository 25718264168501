import React, {useContext} from 'react'
import {deserializeSearchParams, useRouter} from '@nic/commons'
import {SearchPrefixReverseSearch} from '@nic/rdap-api/types/rdap'
import {reverseSearch, reverseSearchInitialState, reverseSearchReducer} from '@nic/rdap-api'
import {DomainTable, ReverseSearchCollectionsForm} from '@nic/rdap-ui'
import {HomeTemplate} from '../../Components/HomeTemplate/HomeTemplate'
import {ContentController} from '../../Components/ContentController/ContentController'
import {AuthContext} from '@nic/kc-auth'

export function ReverseSearch() {
  const {match, location, history} = useRouter()
  const {role} = match.params as { role: SearchPrefixReverseSearch }
  const [state, dispatch] = React.useReducer(reverseSearchReducer, reverseSearchInitialState)
  // const [reset, setReset] = React.useState(false)
  const {keycloak} = useContext(AuthContext)

  React.useEffect(() => {
    if (location.pathname.includes('/domains/reverse_search')) {
      reverseSearch(dispatch, 'domains', role, location.search, keycloak?.authenticated)
    }
    // if (location.pathname.includes('/reverse_search')) {
    //   dispatch({ type: 'REVERSE_SEARCH_FETCH_CLEAN' })
    //   setReset(true)
    // }
  }, [location, role, keycloak?.authenticated])

  const onSubmit = (values: any) => {
    const urlSearchParams = new URLSearchParams()

    if (values[`object`] !== undefined) {
      for (const [key, value] of Object.entries(values)) {
        key !== 'object' && value && urlSearchParams.append(key, value as string)
      }

      urlSearchParams.append(values[`searchPrefix`], values[`search`])
      // console.log('urlSearchParams ', urlSearchParams.toString())
      // console.log('location pathname ', location.pathname)

      history.push(`/${values[`object`]}/reverse_search/entity?${urlSearchParams.toString()}`)
    }
  }
  const deserializedSearchParams = deserializeSearchParams(location.search)
  const hasJsCard = deserializedSearchParams['jscard'] === 'true'
  return (
      <HomeTemplate>
        <div style={{paddingLeft: '10%', paddingRight: '10%'}}>
          <h2>Reverse search</h2>
          <ReverseSearchCollectionsForm
              onSubmit={onSubmit}
              style={{
                textAlign: 'left',
                padding: '24px',
                background: '#fbfbfb',
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
              }}
              initialValues={deserializedSearchParams}
          />
          <div style={{margin: 10}}>
            <ContentController {...state}>
              {(data) => (
                  <div
                      className="search-result-list"
                      style={{
                        marginTop: 20,
                      }}
                  >
                    <DomainTable
                        dataSource={data && data.domainSearchResults}
                        pagingMetdata={data && data.paging_metadata}
                        jsCardEnable={hasJsCard}
                    />
                  </div>
              )}
            </ContentController>
          </div>
        </div>
      </HomeTemplate>
  )
}
