import React, {useContext} from 'react'
import {HomeTemplate} from '../../Components/HomeTemplate/HomeTemplate'
import {searchInitialState, searchLookup, searchReducer} from '@nic/rdap-api'
import {deserializeSearchParams, useRouter} from '@nic/commons'
import {DomainTable, SearchCollectionsForm} from '@nic/rdap-ui'
import {ContentController} from '../../Components/ContentController/ContentController'
import {AuthContext} from '@nic/kc-auth'

export default function Search() {
  const {location, history} = useRouter()
  const {keycloak} = useContext(AuthContext)

  const [state, dispatch] = React.useReducer(searchReducer, searchInitialState)

  React.useEffect(() => {
    if (location.pathname === '/domains') {
      searchLookup(dispatch, 'domains', location.search, keycloak?.authenticated)
    }
  }, [location, keycloak?.authenticated])

  const onSubmit = (values: any) => {
    const urlSearchParams = new URLSearchParams()

    if (values[`object`] !== undefined) {
      for (const [key, value] of Object.entries(values)) {
        key !== 'object' && value && urlSearchParams.append(key, value as string)
      }

      urlSearchParams.append(values[`searchPrefix`], values[`search`])
      history.push(`${values[`object`]}?${urlSearchParams.toString()}`)
    }
  }

  console.log(' deserialize', deserializeSearchParams(location.search))
  return (
      <HomeTemplate>
        <div style={{paddingLeft: '10%', paddingRight: '10%'}}>
          <h2>Search</h2>
          <SearchCollectionsForm
              onSubmit={onSubmit}
              style={{
                textAlign: 'left',
                padding: '24px',
                background: '#fbfbfb',
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
              }}
              initialValues={deserializeSearchParams(location.search)}
          />
          <div style={{margin: 10}}>
            <ContentController {...state}>
              {(data) => (
                  <div
                      className="search-result-list"
                      style={{
                        marginTop: 20,
                      }}
                  >
                    <DomainTable
                        dataSource={data && data.domainSearchResults}
                        pagingMetdata={data && data.paging_metadata}
                    />
                  </div>
              )}
            </ContentController>
          </div>
        </div>
      </HomeTemplate>
  )
}
