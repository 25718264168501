import {Link} from 'react-router-dom'
import React from 'react'
import {AuthContext} from '@nic/kc-auth'

export function Menu() {
    const {keycloak} = React.useContext(AuthContext)

    const searchMenu = () => {
        if (keycloak && keycloak.resourceAccess && keycloak.resourceAccess['rdap-server']) {
            const aa = keycloak.resourceAccess['rdap-server']
            if (!aa.roles.includes('ANONYMOUS')) {
                return (
                    <div style={{display: 'inline'}}>
                        <Link to={'/search'}>Search</Link>
                        {` | `}
                        <Link to={'/reverse_search'}>Reverse search</Link>
                        {` | `}
                    </div>
                )
            }
        }
    }

    if (keycloak?.authenticated) {
        return (
            <div style={{margin: 10}}>
                <Link to={'/'}>Home</Link>
                {` | `}
                {searchMenu()}
                <Link to={'/help'}>Help</Link>
            </div>
        )
    } else {
        return (
            <div style={{margin: 10}}>
                <Link to={'/'}>Home</Link>
                {` | `}
                {searchMenu()}
                <Link to={'/help'}>Help</Link>
                {` | `}
                <Link to={'/login'}>Login</Link>
            </div>
        )
    }
}
