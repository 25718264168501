import React, {useContext} from 'react'

import {buildContentNameserver} from './contentBuilders/buildContentNamerserver'
import {buildContentDomain} from './contentBuilders/buildContentDomain'
import {useRouter} from '@nic/commons'
import {CardWithViewer, DomainStatusBar, IViewerContentType} from '@nic/ui-antd'
import {IDomain, INameServer, LookupType} from '@nic/rdap-api/types/rdap'
import {ObjectClassnameType} from '@nic/rdap-api/src/typings'
import {extractEventDates, lookupFetch, lookupInitialState, lookupReducer} from '@nic/rdap-api'
import {ContentController} from '../../Components/ContentController/ContentController'
import {AuthContext} from '@nic/kc-auth'

export const Lookup = () => {
  const {match, location} = useRouter()
  const {pathSegment, value} = match.params as { pathSegment: string; value: string }
  const [state, dispatch] = React.useReducer(lookupReducer, lookupInitialState)
  const {keycloak} = useContext(AuthContext)

  React.useEffect(() => {
    // inizializza rdapAxios con il token dentro
    lookupFetch(dispatch, pathSegment, value, location.search, keycloak?.authenticated)
  }, [pathSegment, value, location.search, keycloak?.authenticated])

  function content(data: any): IViewerContentType[] | undefined {
    console.log('da ta ', data)

    if (data) {
      const {objectClassName} = data as LookupType
      switch (objectClassName as ObjectClassnameType) {
        case 'domain':
          return buildContentDomain(data as IDomain)
          // case "entity":
          //     return null;
          // case "ip network":
          //     return null;
        case 'nameserver':
          return buildContentNameserver(data as INameServer)
      }
    }
    return undefined
  }

  const ExtraTab = () => {
    const domain = state.data as IDomain
    const {created} = extractEventDates(domain)
    const isJsCard = domain.entities && domain.entities.filter((e) => e.jscard).length > 0
    console.log('isJsCard', isJsCard, location)
    return (
        <DomainStatusBar
            domainName={domain.unicodeName ? domain.unicodeName : domain.ldhName}
            createdDate={created?.eventDate}
            status={domain.status}
            jsCard={isJsCard}
        />
    )
  }

  return (
      <div>
        <h1>
          Lookup {pathSegment} {value}
        </h1>
        <ContentController {...state}>
          {(data) => (
              <div style={{padding: '20px 10% 20px 10%'}}>
                <CardWithViewer defaultView={'tab'} content={content(data)} extraTab={<ExtraTab/>}/>
              </div>
          )}
        </ContentController>
      </div>
  )
}
