import React from 'react'
import {Spin} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import {Error} from '../Error/Error'
import {IFetchStateType} from '@nic/rdap-api/src/typings'

interface IContentController<T> extends IFetchStateType<T> {
  children(data: T): React.ReactElement
}

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>

export function ContentController(props: IContentController<any>): JSX.Element {
  if (props.loading) {
    return (
        <div>
          <Spin indicator={antIcon} style={{display: 'block'}}/>
          Loading...
        </div>
    )
  } else if (props.error) {
    return <Error {...props.error} />
  } else if (props.data) {
    return props.children(props.data)
  } else {
    return <></>
  }
}
