import React from 'react'
import {useRouter} from '@nic/commons'
import {PathSegmentInstance, PathSegmentSelectOption, SearchBar} from '@nic/rdap-ui'
import {HomeTemplate} from '../../Components/HomeTemplate/HomeTemplate'

export function HomePageRDAPSearchForm() {
  const {history} = useRouter()
  return (
      <HomeTemplate style={{marginTop: '10%'}}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <SearchBar
              options={options}
              placeholder={'search a domain, ip or autnum'}
              width={800}
              onSubmit={(v: PathSegmentInstance) => history.push(`/${v.type}/${v.value}`)}
          />
        </div>
      </HomeTemplate>
  )
}

const options: PathSegmentSelectOption[] = [
  {
    value: 'domain',
    description: 'Domain',
    disabled: false,
  },
  {
    value: 'nameserver',
    description: 'Nameserver',
    disabled: true,
  },
  {
    value: 'entity',
    description: 'Entity',
    disabled: true,
  },
  {
    value: 'ip',
    description: 'Ip',
    disabled: true,
  },
  {
    value: 'autnum',
    description: 'AutNum',
    disabled: true,
  },
]
